.hero {
  position: relative;
  width: 100%;
  height: 480px;
  display: flex;
  margin: 0 auto;
  overflow: hidden;
}

.banners {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  margin: 0 auto;
  transition: transform 600ms ease-in-out;
}

.banner {
  position: relative;
  flex: 0 0 100%;
  width: 100%;
  height: 100%;
}

.desktop {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.mobile {
  @extend .desktop;
  display: none;
}

_:-ms-fullscreen,
:root .background {
  width: auto;
}

.copy {
  position: absolute;
  bottom: 32px;
  left: 50%;
  width: 44%;
  max-width: 640px;
  height: 100%;
  font-size: 32px;
  line-height: 120%;
  text-align: left;
  color: #00366f;
}

.inner {
  position: absolute;
  bottom: 0;
  padding: 0 40px 0 0;
}

.title {
  font-weight: bold;
  font-size: 113%;
}

.description {
  margin: 6px 0 28px 0;
  font-weight: 300;
  line-height: 100%;
}

.bottom {
  width: 100%;
  display: flex;
  align-items: center;
}

.learn {
  width: 144px;
  margin-bottom: -6px;
  margin-right: 80px;

  img {
    width: 100%;
    min-height: 1px;
  }
}

.dots {
  display: flex;
  justify-content: center;
}

.dot {
  margin: 0 16px 0 0;
  width: 15px;
  height: 15px;
  border-radius: 100px;
  border: 1px solid #00366f;
  background-color: transparent;
}

.left {
  position: absolute;
  top: 0;
  left: 2%;
  width: 5%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.arrow {
  width: 100%;
  width: 70px;

  img {
    width: 100%;
    min-height: 1px;
  }
}

.right {
  @extend .left;
  left: unset;
  right: 2%;

  .arrow {
    img {
      transform: scaleX(-1);
    }
  }
}

@media (max-width: 1600px) {
  .copy {
    font-size: 30px;
  }

  .arrow {
    width: 64px;
  }
}

@media (max-width: 1400px) {
  .copy {
    font-size: 28px;
  }
}

@media (max-width: 1200px) {
  .hero {
    height: 350px;
  }

  .copy {
    left: 46%;
    width: 50%;
    bottom: 20px;
    font-size: 26px;
  }

  .description {
    margin: 4px 0 18px 0;
  }

  .learn {
    margin-right: 70px;
  }

  .left {
    left: 1%;
  }

  .right {
    left: unset;
    right: 1%;
  }

  .arrow {
    width: 44px;
  }

  .dot {
    margin: 0 12px 0 0;
    width: 10px;
    height: 10px;
  }
}

@media (max-width: 1024px) {
  .banners {
    transition: transform 500ms ease-in-out;
  }

  .copy {
    left: 45%;
    width: 52%;
    font-size: 22px;
  }

  .learn {
    margin-right: 60px;
  }
}

@media (max-width: 768px) {
  .copy {
    left: 44%;
    width: 52%;
    font-size: 18px;
  }
}

@media (max-width: 600px) {
  .hero {
    height: 340px;
  }

  .banners {
    transition: transform 400ms ease-in-out;
  }

  .desktop {
    display: none;
  }

  .mobile {
    display: block;
  }

  .copy {
    left: auto;
    right: 0;
    width: 68%;
    font-size: 18px;
    color: #00366f !important;
  }

  .inner {
    width: 100%;
    right: 0;
    padding: 20px 40px 0 24px;
    box-sizing: border-box;
    background-color: rgba($color: white, $alpha: 0.7);
  }

  .description {
    margin: 4px 0 16px 0;
    line-height: 120%;
  }

  .bottom {
    margin-bottom: 12px;
    display: block;
  }

  .learn {
    margin: 0 0 0 -2px;
    width: 106px;
    flex: 0 0 122px;
  }

  .dots {
    margin-top: 8px;
    justify-content: flex-start;
  }

  .dot {
    margin: 0 12px 0 0;
    border-color: #00366f !important;
  }

  .left {
    left: 2%;
    width: 30px;
  }

  .right {
    left: auto;
    right: 2%;
  }

  .arrow {
    width: 600px;
  }
}

@media (max-width: 400px) {
  .hero {
    height: 300px;
  }

  .copy {
    font-size: 16px;
  }

  .description {
    margin: 4px 0 12px 0;
    line-height: 120%;
  }

  .learn {
    margin-right: 32px;
  }

  .dot {
    margin: 0 16px 0 0;
    width: 8px;
    height: 8px;
  }
}
