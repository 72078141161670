@import "../../variables.scss";

.alert-false {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	background-color: $shaderColor;
	opacity: 0;
	pointer-events: none;

	transition: opacity 300ms ease-in;
}

.alert-true {
	@extend .alert-false;
	opacity: 1;
	pointer-events: all;
}

.message {
	display: block;
	padding: 16px 30px 23px 30px;
	background-color: white;
	border-radius: 10px;

	p {
		text-align: center;
	}

	button {
		width: 70px;
		margin: 0 10px 0 10px;

		img {
			width: 100%;
			min-height: 1px;
		}
	}

	button:hover {
		opacity: 0.8;
	}

	button:active {
		opacity: 0.6;
	}
}

.mobile {
	display: none;
}

.signoff {
	margin: 18px 0 -12px 0;
	display: flex;
	justify-content: space-between;
	font-size: 92%;
}

@media (max-width: 600px) {
	.mobile {
		display: block;
	}
}

@media (max-width: 400px) {
	.message {
		font-size: 14px;
		padding: 16px 24px 20px 24px;
	}
}
