@import "../../variables.scss";

.alert-false {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: $shaderColor;

  opacity: 1;
  pointer-events: all;
  transition: opacity 300ms ease-in;
}

.alert-true {
  @extend .alert-false;
  opacity: 0;
  pointer-events: none;
}

.message {
  display: block;
  width: 70%;
  max-width: 400px;
  min-width: 300px;
  padding: 16px 30px 14px 30px;
  background-color: white;
  border-radius: 10px;

  p {
    text-align: left;
  }

  p:nth-of-type(4) {
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
    margin-right: 10px;
  }

  button {
    margin-bottom: 14px;
    width: 100%;
    border-radius: 8px;
    background-color: #d6007f;
    box-shadow: 3px 3px 15px rgba($color: black, $alpha: 0.5);

    img {
      margin: 0 0 -6px 0;
      width: 100%;
    }
  }

  button:hover {
    opacity: 0.8;
  }

  button:active {
    opacity: 0.6;
  }
}

@media (max-width: 420px) {
  .message {
    width: 80%;
    padding: 10px 20px 10px 20px;
    font-size: 12px;

    p {
      margin: 10px 0 10px 0;
    }

    button {
      margin-bottom: 10px;
    }
  }
}
