.posters {
  display: block;
  color: #4d4e4e;
  font-size: 10px;

  p {
    margin: 0;
    padding: 0;
    color: #4d4e4e;
  }

  button {
    font-size: inherit;
    color: #4d4e4e;
  }
}

.title {
  margin: 20px 0 24px 0;
  color: #004278;
  font-weight: bold;
  font-size: calc(14px + 13 * ((100vw - 400px) / 1100));
}

.table {
  position: relative;
  margin: 0 auto 0 auto;
  width: 90%;
  max-width: 640px;
  overflow: visible hidden;
  text-align: left;
  border: 0.5px solid rgba($color: #004278, $alpha: 0.4);
}

.scroller {
  width: 100%;
  max-height: 420px;
  overflow-y: scroll;
  pointer-events: all;
  scrollbar-width: none; // firefox
  -ms-overflow-style: none; // ie11
}

.scroller::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.scrolled {
  position: relative;
}

.headers,
.row {
  display: flex;
  font-size: 12px;
}

.headers {
  padding-top: 7px;
  font-weight: 900;
  margin-bottom: -4px;
}

.study {
  position: relative;
  margin: 0 0 4px 0;
  padding: 2px 0 0 2%;
  min-height: 14px;
  font-size: 13px;
  font-weight: bold;
  color: #004278;
  background-color: rgba($color: #008bce, $alpha: 0.1);
}

.label {
  padding: 3px 0 1px 1%;
  max-width: 30%;
}

.toggle-false {
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #008bce;
  display: none;
  pointer-events: all;
  transition: right 250ms ease-in-out;

  img {
    width: 12px;
    transition: transform 250ms ease-in-out;
    transform: rotate(0deg);
  }
}

.toggle-true {
  @extend .toggle-false;

  img {
    transform: rotate(180deg);
  }
}

.content {
  flex: 0 0 50%;
  padding: 0 2% 0 3%;
  box-sizing: border-box;
}

_:-ms-fullscreen,
:root .content {
  flex: 0 0 46%;
}

.summary {
  margin: 6px 0 0 0 !important;
}

.authors {
  flex: 0 0 100%;
  padding: 0 2% 0 6px;
  box-sizing: border-box;
}

.headers {
  .content {
    margin-bottom: 10px;
  }

  .authors {
    padding: 0 0 0 6px;
  }
}

.poster {
  margin: 0 0 8px 0;
}

.drawer-false {
  display: flex;
  justify-content: space-between;
  flex: 0 0 50%;
  overflow: hidden;
  max-height: 600px;
}

.pipe {
  color: #d5007f;
}

.channel {
  position: absolute;
  top: 39px;
  right: 0;
  width: 8px;
  height: calc(100% - 40px);
  background-color: #f1f2f2;
  transition: opacity 250ms ease-in-out;
}

.bar {
  width: 100%;
  background-color: #d5007f;
  border-radius: 100px;
  margin-top: 0;
  height: 300px;
}

.gestures {
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 100%;
}

@media (max-width: 1400px) {
  .posters {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 700px) {
  .headers {
    display: none;
  }

  .row {
    display: block;
    padding: 0 12px 0 0;
  }

  .poster {
    display: block;
    margin: 0 0 16px 0;
  }

  .label {
    max-width: 100%;
  }

  .toggle-false {
    display: block;
  }

  .drawer-false {
    display: block;
    margin: 0 0 -10px 0;
    max-height: 0;
  }

  .drawer-true {
    max-height: 600px;
  }

  .authors {
    padding: 8px 0 0 3%;
  }

  .button {
    margin: 4px 0 0 0;
  }

  .channel {
    top: 0;
    height: 100%;
  }

  .gestures {
    width: 8px;
  }
}
