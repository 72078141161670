.cell {
  padding: 4% 0 3% 0;
}

.study {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 16px;
}

.combos {
  margin: 0 1.6% 0 1.6%;
  transform: scale(1);
  flex: 0 0 32px;
  overflow: hidden;
}

.info {
  margin-right: 64px;
  flex: 1;
}

.code {
  margin-top: 8px;
  font-weight: bold;
  font-style: italic;
}

.cell:active {
  .study {
    opacity: 0.7;
  }
}

.centered {
  position: absolute;
  top: 0;
  right: 5%;
  height: 100%;
  display: flex;
  justify-content: center;
  width: 36px;
  height: 100%;
}

.icon {
  margin-top: -10px;
  width: 100%;
  transform: scale(1);
}

@media (max-width: 1400px) {
}

@media (max-width: 1200px) {
  .combos {
    transform: scale(0.9);
  }
}

@media (max-width: 1024px) {
  .study {
    font-size: 14px;
  }

  .combos,
  .icon {
    transform: scale(0.85);
  }
}

@media (max-width: 900px) {
  .phaseTitle {
    height: 45px;
    font-size: 17px;
  }

  .study {
    font-size: 14px;
  }

  .combos,
  .icon {
    transform: scale(0.8);
  }
}

@media (max-width: 768px) {
  .phaseTitle {
    height: 50px;
    font-size: 18px;
  }

  .study {
    font-size: 15px;
  }
}

@media (max-width: 600px) {
  .study {
    font-size: 14px;
  }

  .study {
    font-size: 12px;
  }

  .combos,
  .icon {
    transform: scale(0.7);
  }
}

@media (max-width: 480px) {
  .combos,
  .icon {
    transform: scale(0.65);
  }
}

@media (max-width: 320px) {
  .study {
    font-size: 11px;
  }
}

@media (hover: hover) {
  .icon {
    display: none;
  }
}
