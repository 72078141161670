@import "../../variables.scss";

.pipelineOverview {
  margin-top: 30px;
  width: 100%;
  min-width: 320px;
  background-color: rgba($color: #6bc0da, $alpha: 0.1);
  border: 1px solid rgba($color: #6bc0da, $alpha: 0.1);
}

.inner {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

_:-ms-fullscreen,
:root .inner {
  max-height: 300px;
}

.superscript {
  position: relative;
  top: -0.3em;
  font-size: 80%;
}

.phases {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  @include content_width("width");
}

.breaker {
  display: none;
  width: 100%;
  height: 10px;
}

.footer {
  margin: 10px 0 10px -20px;
  width: 240px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-size: 10px;
  text-align: left;
  // border: 1px solid orange;
}

.footerSmall {
  position: absolute;
  top: 102%;
  left: 0;
  width: 100%;
  min-width: 235px;
  font-size: 10px;
  text-align: left;
  display: none;
}

.ref {
  text-indent: -3px;
}

@media (max-width: 1200px) {
  .inner {
    max-height: 260px;
  }

  _:-ms-fullscreen,
  :root .inner {
    max-height: 270px;
  }

  .footer {
    margin: 10px 0 10px -10px;
    width: 200px;
  }
}

@media (max-width: 1024px) {
  .inner {
    max-height: 230px;
  }

  _:-ms-fullscreen,
  :root .inner {
    max-height: 240px;
  }

  .limited {
    right: -2%;
  }

  .footer {
    margin: 10px 0 10px -15px;
    width: 180px;
  }
}

@media (max-width: 900px) {
  .inner {
    max-height: 200px;
  }

  _:-ms-fullscreen,
  :root .inner {
    max-height: 210px;
  }

  .footer {
    margin: 10px 0 10px -25px;
    height: 40px;
  }
}

@media (max-width: 768px) {
  .inner {
    max-height: 660px;
  }

  _:-ms-fullscreen,
  :root .inner {
    max-height: 670px;
  }

  .phase {
    width: 40%;
  }

  .breaker {
    display: block;
  }

  .footer {
    margin: 10px 0 10px 0px;
    height: 44px;
  }
}

@media (max-width: 600px) {
  .inner {
    max-height: 610px;
  }

  .footer {
    margin: 10px 0 10px -10px;
  }
}

@media (max-width: 480px) {
  .inner {
    max-height: 520px;
  }

  .footer {
    margin: 10px 0 10px -40px;
    width: 200px;
  }
}

@media (max-width: 400px) {
  .footer {
    display: none;
  }

  .footerSmall {
    display: block;
  }

  .ref {
    line-height: 115%;
  }

  .ref:last-child {
    margin-top: 4px;
  }
}

@media (max-width: 320px) {
  .inner {
    max-height: 400px;
  }
}
