@import "../../variables.scss";

.lightbox-false {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  opacity: 0;
  pointer-events: none;
  transition: opacity 300ms ease-in;
}

.lightbox-true {
  @extend .lightbox-false;
  opacity: 1;
  pointer-events: all;
}

.fader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $shaderColor;
}

.column {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.close {
  margin: 10px 0 0 94%;
  width: 40px;

  img {
    width: 100%;
  }
}

.close:active {
  opacity: 0.7;
}

.scroller {
  flex: 1;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.content {
  position: relative;
  margin-top: 5%;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100%;
}

.graphic {
  width: 1200px;
  margin-bottom: 100px;
  background-color: white;
}

.graphicLoading {
  @extend .graphic;
  opacity: 0;
}

.download {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0 70px 0;
}

.icon {
  margin-right: 20px;
}

.directions {
  font-size: 27px;
  font-weight: bold;
}

.inquiry {
  font-size: 18px;
  font-weight: 500;
  width: 75%;
}

a:hover {
  .directions {
    text-decoration: underline;
  }
}

@media (max-width: 1400px) {
  .graphic {
    width: 1000px;
  }
}

@media (max-width: 1200px) {
  .graphic {
    width: 800px;
  }

  .icon {
    width: 14%;
  }

  .directions {
    font-size: calc(14px + 13 * ((100vw - 400px) / 1000));
  }
}

@media (max-width: 900px) {
  .graphic {
    width: 768px;
  }
}

@media (max-width: 768px) {
  .graphic {
    width: 100%;
  }

  _:-ms-fullscreen,
  :root .graphic {
    width: 100vw;
  }

  .icon {
    width: 14%;
  }

  .directions {
    font-size: calc(14px + 13 * ((100vw - 400px) / 1000));
  }

  .close {
    margin: 10px 0 0 90%;
    width: 30px;
  }
}
