.phase {
  position: relative;
  max-width: 200px;
  width: 20%;
  overflow: visible;
}

.phase:active {
  opacity: 0.7;
}

.bg {
  width: 100%;
}

.title {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  font-size: 24px;
  letter-spacing: 1px;
  font-weight: bold;
}

.studies {
  position: absolute;
  top: 80px;
  left: 0;
  width: 100%;
  height: 72px;
}

.number {
  width: auto;
  height: 100%;
  margin: 0;
  padding: 0;
}

.spacer {
  display: inline-block;
}

@media (max-width: 1200px) {
  .title {
    font-size: 24px;
  }
}

@media (max-width: 1024px) {
  .title {
    font-size: 20px;
  }
}

@media (max-width: 900px) {
  .title {
    font-size: 17px;
  }
}

@media (max-width: 768px) {
  .phase {
    width: 40%;
  }

  .phase:nth-child(1) {
    margin: 0 12% 12% 0;
  }

  .phase:nth-child(4) {
    margin-right: 12%;
  }

  .title {
    font-size: calc(12px + 14 * ((100vw - 320px) / 448));
  }
}

@media (max-width: 350px) {
  .title {
    font-size: 12px;
  }
}
