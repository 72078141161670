.container {
  width: calc(100% - 20px);
}

.cell {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #868686;
  background-color: white;
  cursor: pointer;
}

.disabled {
  @extend .cell;
  pointer-events: none;
}

.cell:last-child {
  border-bottom: none;
}

.agent {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 177px;
}

.image {
  width: 140px;
  margin: 0 34px 0 34px;
}

.info {
  text-align: left;
  font-size: 27px;

  b {
    font-weight: 500;
  }
}

.cell:active {
  .agent {
    opacity: 0.7;
  }
}

.pink {
  color: #da037f;
}

@media (max-width: 1400px) {
  .agent {
    height: 160px;
  }

  .image {
    width: 140px;
    margin: 0 34px 0 34px;
  }

  .info {
    font-size: 26px;
  }
}

@media (max-width: 1200px) {
  .agent {
    height: 155px;
  }

  .image {
    width: 130px;
    margin: 0 24px 0 24px;
  }

  .info {
    font-size: 24px;
  }
}

@media (max-width: 1024px) {
  .agent {
    height: 147px;
  }

  .image {
    width: 120px;
    margin: 0 20px 0 20px;
  }

  .info {
    font-size: 22px;
  }
}

@media (max-width: 900px) {
  .agent {
    height: 147px;
  }

  .image {
    width: 115px;
    margin: 0 18px 0 18px;
  }

  .info {
    font-size: 21px;
  }
}
@media (max-width: 768px) {
  .agent {
    height: calc(120px + 50 * ((100vw - 400px) / 320));
  }

  .image {
    width: calc(100px + 40 * ((100vw - 400px) / 320));
    margin: 0 6% 0 6%;
  }

  .info {
    font-size: calc(17px + 10 * ((100vw - 400px) / 320));
  }
}

@media (max-width: 600px) {
}

@media (max-width: 480px) {
}

@media (max-width: 320px) {
}
