.menu {
  position: absolute;
  left: 0;
  width: 100%;
  overflow: hidden;
  z-index: 100;
}

.menu-false {
  width: 100%;
  display: none;
  text-align: left;
  background-color: white;
  margin-top: -190px;
  transition: margin 300ms ease-in-out;
}

.menu-true {
  @extend .menu-false;
  margin-top: 0;
}

.button:active {
  opacity: 0.7;
}

.link {
  width: 100%;
  border: 1px solid #e5e5e5;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .icon,
  .white {
    margin: 14px 5px 14px 8%;
  }

  .white {
    display: none;
  }
}

.link:hover {
  background-color: #da037f;

  .icon {
    display: none;
  }

  .white {
    display: block;
  }
}

@media (max-width: 768px) {
  .menu-false {
    display: block;
  }
}
