$pink: #da037f;
$shaderColor: rgba(
  $color: #011d3a,
  $alpha: 0.98,
);

@mixin content_width($prop) {
  #{$prop}: 1060px;

  @media (max-width: 1200px) {
    #{$prop}: 900px;
  }

  @media (max-width: 1024px) {
    #{$prop}: 768px;
  }

  @media (max-width: 900px) {
    #{$prop}: 670px;
  }

  @media (max-width: 768px) {
    #{$prop}: 75%;
  }

  @media (max-width: 400px) {
    #{$prop}: 94%;
  }

  @media (max-width: 320px) {
    #{$prop}: 300px;
  }
}
