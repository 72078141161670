@font-face {
  font-family: "HelveticaNeueLTPro";
  src: url("./fonts/HelveticaNeueLTPro-Lt.otf") format("opentype");
  font-weight: 300;
}

@font-face {
  font-family: "HelveticaNeueLTPro";
  src: url("./fonts/HelveticaNeueLTPro-Roman.otf") format("opentype");
}

@font-face {
  font-family: "HelveticaNeueLTPro";
  src: url("./fonts/HelveticaNeueLTPro-Roman.otf") format("opentype");
}

@font-face {
  font-family: "HelveticaNeueLTPro";
  src: url("./fonts/HelveticaNeueLTPro-Md.otf") format("opentype");
  font-weight: 500;
}

@font-face {
  font-family: "HelveticaNeueLTPro";
  src: url("./fonts/HelveticaNeueLTProBd.otf") format("opentype");
  font-weight: bold;
}

@font-face {
  font-family: "HelveticaNeueLTPro";
  src: url("./fonts/HelveticaNeueLTPro-Hv.otf") format("opentype");
  font-weight: 900;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

body {
  margin: 0;
  padding: 0;
  font-family: "HelveticaNeueLTPro", sans-serif;
  font-weight: 400;
  cursor: default;
  color: #00366f;
  background-color: rgb(138, 138, 138);
}

#root {
  display: flex;
  justify-content: center;
}

a {
  color: #00366f;
  text-decoration: none;
}

hr {
  margin: 80px auto 20px auto;
  border-top: 1px solid #e5e5e5;
  width: 67%;
}

a {
  cursor: pointer;
}

button {
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  background-color: transparent;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  cursor: pointer;
  color: #00366f;
  font-family: "HelveticaNeueLTPro", sans-serif;
}

button:hover {
  opacity: 0.9;
}

button:active {
  opacity: 0.7;
}

img {
  pointer-events: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

svg {
  background: none;
  border: none;
  background-color: transparent;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  cursor: pointer;
}

.desktop {
  display: block;
}

.mobile {
  display: none;
}

sup {
  vertical-align: baseline;
  position: relative;
  top: -0.4em;
  font-size: 65%;
  white-space: nowrap;
}

@media (max-width: 1024px) {
  hr {
    margin: 70px auto 15px auto;
  }
}

@media (max-width: 768px) {
  .desktop {
    display: none;
  }

  .mobile {
    display: block;
  }
}

@media (max-width: 600px) {
  hr {
    margin: 55px auto 10px auto;
  }
}
