@import "../../variables.scss";

.locations {
	margin: 0 auto 0 auto;
	@include content_width("width");
}

.map {
	width: 100%;
	margin-bottom: -50%;
}

.copy {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	width: 100%;
}

.column {
	text-align: left;
	width: 20%;

	h2 {
		font-size: 20px;
	}

	ul {
		margin: -10px 0 0 -24px;
		font-size: 18px;
		text-align: left;
		list-style: none;
	}

	li {
		margin-top: 3px;
	}

	ul li::before {
		content: "\2022";
		color: #da037f;
		font-weight: bold;
		display: inline-block;
		width: 1em;
		margin-left: -1em;
	}
}

.inset {
	width: 33%;

	p {
		padding: 20px 25px 20px 25px;
		text-align: left;
		font-size: 16px;
		line-height: 130%;
		font-weight: lighter;
		color: #666666;
		border: 1px solid #da037f;
		text-indent: -6px;
	}
}

@media (max-width: 1400px) {
	.column {
		h2 {
			font-size: calc(14px + 6 * ((100vw - 400px) / 1000));
		}

		ul {
			font-size: calc(12px + 6 * ((100vw - 400px) / 1000));
		}
	}

	.inset {
		p {
			font-size: calc(12px + 4 * ((100vw - 400px) / 1000));
		}
	}
}

@media (max-width: 768px) {
	.column {
		width: 32%;
	}

	.inset {
		margin-top: 10px;
		flex-basis: 100%;
		width: 96%;

		p {
			padding: 20px 20px 20px 20px;
			line-height: 170%;
			color: #00366f;
		}
	}
}
