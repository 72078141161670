.category {
  cursor: pointer;
  overflow: hidden;
  padding-right: 12px;
}

.button {
  position: relative;
  width: 100%;
  height: 108px;
  text-align: left;
}

.slider {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: left 200ms ease-in-out;
}

.button:hover {
  .slider {
    left: -100%;
  }
}

.spacer-true {
  height: 4px;
  transition: height 250ms ease-in-out;
}

.spacer-false {
  @extend .spacer-true;
  height: 0;
}

.category:last-child {
  .spacer-true {
    height: 0;
    transition: none;
  }
}

.molecule {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}

.title {
  position: absolute;
  top: 0;
  left: 0;
  width: 95%;
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: 500;
  line-height: 105%;
  color: white;
  white-space: pre-wrap;
}

.arrow {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 72px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    margin-right: 18px;
    width: 44%;
    transition: transform 300ms ease-in-out;
  }
}

.studies {
  transition: max-height 300ms ease-in-out;
}

.drawer {
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: height 250ms ease-in-out;
}

.phases {
  text-align: left;
}

@media (max-width: 1400px) {
  .title {
    font-size: 22px;
  }
}

@media (max-width: 1200px) {
  .title {
    font-size: 18px;
  }

  .arrow {
    width: 65px;
  }
}

@media (max-width: 1024px) {
  .title {
    font-size: 17px;
  }

  .arrow {
    width: 60px;
  }
}

@media (max-width: 900px) {
  .title {
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .title {
    font-size: 22px;
  }

  .arrow {
    width: 70px;
  }
}

@media (max-width: 600px) {
  .title {
    font-size: 20px;
  }

  .arrow {
    width: 65px;
  }
}

@media (max-width: 480px) {
  .title {
    width: 85%;
    font-size: 16px;
  }

  .arrow {
    width: 75px;
    img {
      width: 40%;
      margin-right: 20px;
    }
  }
}

@media (max-width: 320px) {
  .title {
    font-size: 16px;
  }

  .arrow {
    width: 55px;
  }
}
