.button {
  position: fixed;
  bottom: 5px;
  right: 10px;
  width: 60px;

  img {
    width: 100%;
  }
}

.button:active {
  opacity: 0.7;
}

.hidden {
  @extend .hidden;
  display: none;
}

@media (max-width: 800px) {
  .button {
    display: none;
  }
}
