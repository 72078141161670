.app {
  width: 100%;
  height: 100%;
  max-width: 1920px;
  min-width: 310px;
  background-color: white;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.scroller {
  flex: 1;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}

.content {
  position: relative;
  width: 100%;
  text-align: center;
}

.source {
  position: absolute;
  pointer-events: none;
  left: 0px;
  top: 80px;
  width: 1920px;
  height: 1184px;
  width: 375px;
  height: 1050px;
  background-image: url("../../images/source/mobile.jpg");
  opacity: 0.5;
  display: none;
}
