.phase {
  text-align: left;
}

.phaseTitle {
  margin-left: 4%;
  height: 60px;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 20px;
}

@media (max-width: 1400px) {
  .phaseTitle {
    height: 60px;
    font-size: 20px;
  }
}

@media (max-width: 1200px) {
  .phaseTitle {
    height: 55px;
    font-size: 19px;
  }
}

@media (max-width: 1024px) {
  .phaseTitle {
    height: 50px;
    font-size: 18px;
  }
}

@media (max-width: 900px) {
  .phaseTitle {
    height: 45px;
    font-size: 17px;
  }
}

@media (max-width: 768px) {
  .phaseTitle {
    height: 50px;
    font-size: 18px;
  }
}

@media (max-width: 600px) {
  .phaseTitle {
    height: 45px;
    font-size: 15px;
  }
}

@media (max-width: 480px) {
  .phaseTitle {
    height: 40px;
    width: 85%;
    font-size: 13px;
  }
}

@media (max-width: 320px) {
  .phaseTitle {
    height: 30px;
    font-size: 13px;
  }
}
