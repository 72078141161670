@import "../../variables.scss";

.header {
  width: 100%;
  margin: 36px 0 0 0;
  box-sizing: border-box;
  text-align: center;
  text-transform: uppercase;
  color: $pink;
  font-weight: bold;
  font-size: 27px;
  letter-spacing: 2.6px;
}

.headerPadded {
  @extend .header;
  @include content_width("width");
}

@media (max-width: 1400px) {
  .header {
    font-size: calc(14px + 13 * ((100vw - 400px) / 1100));
    letter-spacing: calc(0.5px + 1.6 * ((100vw - 400px) / 1100));
  }
}
