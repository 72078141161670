@import "../../variables.scss";

.footer {
  margin-top: 120px;
  width: 100%;
  height: auto;
  min-width: 320px;
  padding: 70px 0 40px 0;
  box-sizing: border-box;
  background-color: rgba($color: #6bc0da, $alpha: 0.1);
}

.disclaimer {
  margin: 0 auto 0 auto;
  @include content_width("width");
  color: #666666;
  font-size: 20px;
  line-height: 160%;
  padding: 20px;
  box-sizing: border-box;
  text-align: left;
  border: 1px solid rgb(70, 70, 70);
}

.signoff {
  margin: 70px auto 0 auto;
  @include content_width("width");
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 10px;
  color: #221b1c;

  span {
    margin: 0 16px 0 16px;
  }
}

.logo {
  width: 80px;
}

.breaker {
  display: none;
  flex-basis: 100%;
  height: 0;
}

.copy {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-left: 10px;
}

.intention {
  flex: 1;
  text-align: right;
}

@media (max-width: 1400px) {
  .disclaimer {
    font-size: calc(16px + 4 * ((100vw - 400px) / 1000));
  }
}

@media (max-width: 768px) {
  .footer {
    margin-top: 80px;
  }

  .signoff {
    justify-content: center;
    flex-wrap: wrap;
    min-width: 320px;
    span {
      margin: 0;
    }
  }

  .copy {
    margin: 0;
    justify-content: space-between;
    align-items: center;
  }

  .breaker {
    display: block;
  }

  .logo {
    margin: 0 0 40px 0;
  }

  .intention {
    margin-top: 18px;
  }
}

@media (max-width: 480px) {
  .intention {
    text-align: center;
  }
}
