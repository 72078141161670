@import "../../variables.scss";

.contact {
  position: relative;
  margin: 0 auto 0 auto;
  @include content_width("width");
  min-width: 320px;
}

.disclaimer {
  text-align: center;
  width: 94%;
  margin: 24px auto 40px auto;
  font-size: 20px;
  line-height: 150%;

  button {
    font-size: 100%;
  }
}

.copy {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.left {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex: 1;
}

.info {
  text-align: left;
  width: 72%;
  margin-right: 15%;
}

.h2 {
  font-weight: bold;
  font-size: 27px;
  line-height: 130%;
}

.directions {
  flex: 1;
  width: 80%;
  margin-top: 10px;
  font-size: 20px;
  line-height: 150%;

  a:hover {
    text-decoration: underline;
  }
}

.trials {
  display: inline;
  font-size: inherit;
}

.trials:hover {
  text-decoration: underline;
}

.breaker {
  display: none;
  width: 100%;
  height: 2px;
  border: 1px solid white;
}

.right {
  text-align: left;
  flex: 1;
}

.h3 {
  font-weight: bold;
  font-size: 20px;
}

.link {
  margin-top: 6px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.details {
  margin: 4px 0 0 10px;
  font-size: 20px;
}

.spacer {
  margin-top: 50px;
}

.spacerSmall {
  margin-top: 30px;
}

@media (max-width: 1400px) {
  .h2 {
    font-size: calc(20px + 7 * ((100vw - 400px) / 1000));
  }

  .disclaimer,
  .directions {
    font-size: calc(12px + 8 * ((100vw - 400px) / 1000));
  }

  .h3 {
    font-size: calc(12px + 8 * ((100vw - 400px) / 1000));
  }
}

@media (max-width: 1024px) {
  .details {
    font-size: 18px;
  }
}

@media (max-width: 900px) {
  .details {
    font-size: 17px;
  }
}

@media (max-width: 768px) {
  .disclaimer,
  .copy {
    justify-content: center;
    flex-wrap: wrap;
  }

  .left {
    margin-right: 0;
    width: 100%;
  }

  .info {
    width: 100%;
  }

  .breaker {
    display: block;
  }

  .h3 {
    margin-top: 30px;
  }

  .spacer {
    margin-top: 10px;
  }

  .details {
    font-size: 16px;
  }
}

@media (max-width: 600px) {
  .icon {
    width: 20px;
  }

  .info {
    margin-right: 10%;
  }

  .directions {
    width: 90%;
  }

  .details {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .info {
    margin-right: 0;
  }

  .directions {
    width: 100%;
  }

  .details {
    font-size: 14px;
  }
}

@media (max-width: 320px) {
  .details {
    font-size: 13px;
  }
}
