.title {
  margin: 0 auto 0 auto;
  width: 100%;
  max-width: 1920px;
}

.row {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  box-shadow: 0 3px 16px rgba($color: black, $alpha: 0.04);
}

.left,
.right {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.logo {
  margin-right: 30px;
  cursor: pointer;

  img {
    width: 100%;
  }
}

.center {
  margin: 24px 2% 24px 2%;
  flex: 4;
}

.name {
  margin: 0 0 0 0;
  font-weight: lighter;
  font-size: 29px;
  letter-spacing: 5px;
}

.rule {
  display: block;
  margin: 4px auto 4px auto;
  max-width: 620px;
  width: 80%;
  border-top: 1px solid #e5e5e5;
}

.nav {
  margin-top: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button {
  padding: 0 12px 0 12px;
  box-sizing: border-box;
}

.link {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: visible;
}

.menu-false {
  position: relative;
  display: none;
  overflow: visible;
}

.menu-true {
  @extend .menu-false;
}

@media (min-width: 1400px) {
  .left {
    justify-content: center;
  }

  .logo {
    margin-right: 0;
  }
}

@media (max-width: 1200px) {
  .logo {
    width: 90px;
  }

  .name {
    font-size: 27px;
    letter-spacing: 4px;
  }

  .rule {
    margin: 2px auto 4px auto;
  }
}

@media (max-width: 1024px) {
  .logo {
    width: 90px;
  }

  .name {
    margin-top: 16px;
    font-size: 24px;
    letter-spacing: 3px;
  }

  .nav {
    margin-top: 4px;
  }

  .button {
    padding: 0 6px 0 6px;
  }

  .link {
    transform: scale(0.9);
  }
}

@media (max-width: 900px) {
  .logo {
    width: 70px;
  }

  .name {
    margin-top: 16px;
    font-size: 21px;
    letter-spacing: 2px;
  }

  .nav {
    margin-top: 2px;
  }

  .button {
    padding: 0;
  }

  .link {
    transform: scale(0.8);
  }
}

//
// MOBILE
//
@media (max-width: 768px) {
  .center {
    margin: 25px 2% 25px 2%;
  }

  .logo {
    width: 50px;
    margin-left: 10px;
  }

  .name {
    margin-top: 6px;
    font-size: 20px;
    letter-spacing: 1px;
  }

  .rule {
    display: none;
  }

  .nav {
    display: none;
  }

  .menu-false {
    position: relative;
    display: block;
    width: 100px;

    svg {
      transform: scale(1.8) translateY(2px);
    }
  }
}

@media (max-width: 600px) {
  .logo {
    width: 45px;
  }

  .name {
    font-size: 16px;
    letter-spacing: 1px;
  }

  .menu-false {
    width: 45px;

    svg {
      transform: scale(1.5) translateY(2px);
    }
  }
}

@media (max-width: 500px) {
  .center {
    margin: 15px 5px 15px 5px;
  }

  .logo {
    width: 40px;
  }

  .name {
    font-size: 13px;
    letter-spacing: 0px;
  }

  .menu-false {
    width: 40px;

    svg {
      transform: scale(1.3) translateY(2px);
    }
  }
}

@media (max-width: 370px) {
  .logo {
    width: 32px;
  }

  .name {
    margin-top: 3px;
    font-size: 11px;
    letter-spacing: 0px;
  }

  .menu-false {
    width: 34px;
  }
}
