.pipeline {
  width: 100%;
  min-width: 320px;
}

.container {
  margin: 70px auto 0 auto;
  width: 1060px;
}

.headers {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.spacer {
  width: 6%;
}

.header {
  flex: 1;
  height: 145px;
  border-radius: 6px 6px 0 0;
  border: 1px solid #00366f;
  border-bottom: 1px solid #da037f;
  pointer-events: none;
}

.heading {
  font-weight: bold;
  font-size: 27px;
  line-height: 120%;
  letter-spacing: 1.5px;
  margin: 6px 0 6px 0;
}

.description {
  font-size: 21px;
  letter-spacing: 0.2px;
}

.bracket {
  margin: 0 10px 0 10px;
  color: #da037f;
}

.indiLeft {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 50%;
  height: 5px;
  background-color: #da037f;
  display: none;
  transition: transform 200ms ease-in-out;
}

.indiRight {
  @extend .indiLeft;
  transform: translateX(100%);
}

.explorers {
  width: calc(100% - 0px);
  overflow: hidden;
}

.scroller-left-false {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  transition: none;
  transform: translateX(0);
}

.scroller-right-false {
  @extend .scroller-left-false;
  transition: none;
  transform: translateX(-100%);
}

.scroller-left-true {
  @extend .scroller-left-false;
  transition: transform 200ms ease-in-out;
  transform: translateX(0);
}

.scroller-right-true {
  @extend .scroller-left-true;
  transform: translateX(-100%);
}

@media (max-width: 1400px) {
  .container {
    width: 1000px;
  }

  .header {
    height: calc(90px + 50 * ((100vw - 400px) / 1000));
  }

  .bracket {
    margin: 0 2% 0 2%;
  }

  .heading {
    font-size: calc(14px + 13 * ((100vw - 400px) / 1000));
  }

  .description {
    font-size: calc(10px + 11 * ((100vw - 400px) / 1000));
    letter-spacing: 0;
  }
}

@media (max-width: 1200px) {
  .container {
    width: 900px;
  }
}

@media (max-width: 1024px) {
  .container {
    width: 800px;
  }
}

@media (max-width: 900px) {
  .container {
    width: 94%;
  }

  .spacer {
    width: 4%;
  }
}

@media (max-width: 768px) {
  .headers {
    border-radius: 6px 6px 0 0;
    border: 1px solid #00366f;
    border-bottom: 1px solid #da037f;
  }

  .header {
    border: none;
    pointer-events: all;
  }

  .spacer {
    display: none;
  }

  .indiLeft {
    display: block;
  }

  .explorers {
    border-radius: 0 0 6px 6px;
    border: 1px solid #00366f;
    border-top: none;
    width: calc(100% - 2px);
  }
}

@media (max-width: 600px) {
}

@media (max-width: 480px) {
}

@media (max-width: 320px) {
}
