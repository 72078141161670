.explorer {
  position: relative;
  flex: 1;
  height: 444px;
  border-radius: 0 0 6px 6px;
  border: 1px solid #00366f;
  border-top: none;
}

.container {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.scroller {
  width: calc(100% + 20px);
  height: 100%;
  overflow-y: scroll;
}

.barContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 5px);
  pointer-events: none;
}

.gestures {
  position: absolute;
  top: 0;
  right: 0;
  width: 16px;
  height: calc(100% + 8px);
  pointer-events: all;
  background-color: white;
  box-shadow: inset 0 0 3px rgba($color: black, $alpha: 0.7);
}

.bar {
  position: absolute;
  top: 0;
  right: 0;
  width: 16px;
  height: 50%;
  border-radius: 5px;
  background-color: #da037f;
  transition: height 200ms ease-in-out;
}

@media (max-width: 768px) {
  .explorer {
    flex: none;
    width: 100%;
    border: none;
  }
}
