@import "../../variables.scss";

.mission {
  margin: 10px auto 0 auto;
  @include content_width("width");
  text-align: left;
  font-size: 27px;

  h2 {
    margin: 20px 0 0 0;
    font-size: 100%;
    line-height: 100%;
  }

  h3 {
    margin: 40px 0 0 0;
    font-size: 100%;
  }

  p {
    margin: 6px 0 0 0;
    font-size: 74%;
    font-weight: lighter;
    line-height: 160%;
  }
}

@media (max-width: 1400px) {
  .mission {
    font-size: calc(14px + 13 * ((100vw - 400px) / 1000));
  }
}
